@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.splash-screen {
  align-items: center;
  background: url('../assets/images/manifest/manifest1_1920.png');
  background-size: cover;
  display: flex;
  height: 100%;
  justify-content: center;
  transition: opacity var(--short-transition) ease-out;
  width: 100%;
  z-index: 100;

  &.fade-out {
    opacity: 0;
  }

  > .image-container {
    --animation-start: 300ms;
    --animation-shift: 200ms;
    --animation-duration: 800ms;
    --img-height: 140px;
    --img-width: 310px;

    height: var(--img-height);
    position: relative;
    width: var(--img-width);

    > img {
      animation-duration: var(--animation-duration);
      animation-name: appear;
      height: var(--img-height);
      opacity: 0;
      position: absolute;
      width: var(--img-width);

      &:last-child {
        animation-fill-mode: forwards;
      }

      @for $i from 1 through 8 {
        &:nth-child(#{$i}) {
          animation-delay: calc(var(--animation-start) + $i * var(--animation-shift));
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .splash-screen {
    > img {
      height: 130px;
    }
  }
}

@media (max-width: 400px) {
  .splash-screen {
    > img {
      height: 120px;
    }
  }
}
