.carousel {
  position: relative;

  > .carousel__slider {
    display: flex;
    height: 100%;
    position: relative;
    width: 100%;

    > .carousel__slide {
      align-items: center;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      height: 100%;
      justify-content: center;
      opacity: 0;
      position: absolute;
      transition: opacity var(--short-transition) ease-out;
      width: 100%;

      &.visible {
        opacity: 1;
      }

      > .slide__text-container {
        align-items: center;
        color: var(--white);
        display: flex;
        flex-direction: column;
        margin-top: -5rem;

        > .slide__text-pre {
          font-size: 1.2rem;
        }

        > .slide__text-title {
          font: 4.2rem var(--font-family-verbatim);
          margin: 1rem 5.2rem 3rem;
          text-align: center;
        }

        > .slide__text-content {
          font-size: 1.2rem;
          line-height: 1.8rem;
          text-align: center;
        }

        > .slide__logo {
          margin-bottom: 3rem;
          width: 290px;
        }
      }
    }
  }

  > .carousel__buttons {
    position: absolute;
    right: 3%;
    top: calc(50% - 18px);
    z-index: 50;

    > .bullet {
      margin: 0 5px;
    }
  }

  > .carousel__scroll {
    align-items: center;
    bottom: 20px;
    column-gap: .6rem;
    display: flex;
    position: absolute;
    left: 50%;
    transform: rotate(-90deg);
    transform-origin: left;

    > .carousel__scroll-line {
      background-color: var(--white);
      height: 1px;
      transform: scaleX(0);
      transform-origin: left;
      transition: transform 1.5s ease-out;
      width: 60px;

      &.visible {
        transform: scaleX(1);
      }
    }

    > .carousel__scroll-text {
      color: var(--white);
      font-size: .5rem;
      letter-spacing: .2rem;
      text-decoration: none;
      text-transform: uppercase;
    }
  }

  > .menu__closed {
    color: var(--white);
    position: absolute;
    right: 2.1rem;
    top: 2.9rem;

    &::after {
      border-color: var(--white);
    }
  }

  > img {
    &.logo {
      position: absolute;
      height: calc(var(--header-height) * .6);
      left: 20px;
      top: calc(var(--header-height) * .2);
    }
  }
}

@media (width <= 1000px) {
  .carousel {
    > .carousel__slider {
      > .carousel__slide {
        > .slide__text-container {
          margin-top: -20%;
        }
      }
    }

    > .carousel__buttons {
      bottom: 20%;
      left: calc(50% - 52px);
      top: unset;
    }
  }
}

@media (width <= 600px) {
  .carousel {
    > .carousel__slider {
      > .carousel__slide {
        > .slide__text-container {
          > .slide__logo {
            width: 216px;
          }

          > .slide__text-pre,
          > .slide__text-content {
            font-size: 1.2rem;
            padding-inline: 1rem;
          }
        }
      }
    }
  }
}

@media (width <= 400px) {
  .carousel {
    > .carousel__slider {
      > .carousel__slide {
        > .slide__text-container {
          > .slide__logo {
            width: 120px;
          }
        }
      }
    }
  }
}

@media (height <= 800px) {
  .carousel {
    > .carousel__scroll {
      > .carousel__scroll-line {
        width: 40px;
      }
    }
  }
}

@media (height <= 600px) {
  .carousel {
    > .carousel__slider {
      > .carousel__slide {
        > .slide__text-container {
          > .slide__text-title {
            font-size: 4rem;
            margin-bottom: 2rem;
            line-height: 3.7rem;
          }

          > .slide__text-pre,
          > .slide__text-content {
            font-size: 1.4rem;
          }
        }
      }
    }

    > .carousel__scroll {
      bottom: 15px;

      > .carousel__scroll-line {
        width: 40px;
      }
    }
  }
}

@media (height <= 500px) {
  .carousel {
    > .carousel__slider {
      > .carousel__slide {
        > .slide__text-container {
          margin-top: -15%;

          > .slide__text-title {
            font-size: 3rem;
            margin-bottom: 1rem;
          }
        }
      }
    }

    > .carousel__buttons {
      bottom: 25%;
    }

    > .carousel__scroll {
      bottom: 10px;

      > .carousel__scroll-line {
        width: 30px;
      }
    }
  }
}

@media (height <= 400px) {
  .carousel {
    > .carousel__scroll {
      bottom: 15px;

      > .carousel__scroll-line {
        width: 20px;
      }
    }
  }
}
