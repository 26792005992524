.modal__wrapper {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1040;

  > .modal__overlay {
    background-color: var(--modal-overlay-background);
    inset: 0;
    position: absolute;
    z-index: 1050;
  }

  > .modal {
    align-items: stretch;
    background: var(--white);
    display: flex;
    flex-direction: column;
    margin: auto;
    position: relative;
    z-index: 1060;

    > .modal__image {
      background-image: url('../assets/images/newsletter/newsletter_510.png');
      background-repeat: no-repeat;
      background-size: cover;
      height: 120px;
      width: 100%;
    }

    > .modal__close-button {
      background-color: rgba(255, 255, 255, .5);
      border: 0;
      border-radius: 50%;
      cursor: pointer;
      height: 1.6rem;
      padding: .3rem;
      position: absolute;
      right: -.8rem;
      top: -.8rem;
      width: 1.6rem;

      &:hover {
        background-color: rgba(255, 255, 255, .7);
      }
    }

    > .modal__header {
      align-items: stretch;
      display: flex;
      flex-direction: column;
      font: 2rem var(--font-family-verbatim);
      padding: 2rem;
    }

    > .modal__content {
      padding: 0 2rem 2rem;
    }
  }
}

@media (max-width: 400px) {
  .modal__wrapper {
    > .modal {
      > .modal__header {
        font: 1.4rem var(--font-family-title);
        padding: 1rem;
      }

      > .modal__content {
        padding: 0 1rem 1rem;
      }
    }
  }
}

@media (max-width: 320px) {
  .modal__wrapper {
    > .modal {
      > .modal__image {
        background-image: url('../assets/images/newsletter/newsletter_320.png');
      }
    }
  }
}
