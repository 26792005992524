.page-creation {
  a {
    color: var(--black);
    margin-left: .4rem;
  }

  > .page-creation__container {
    align-items: center;
    display: grid;
    grid-template-columns: 20% auto 20%;

    > .page-creation__section {
      position: relative;

      &.section__customers {
        background-color: var(--bright-green);
      }

      &.section__left,
      &.section__right {
        height: 100%;
      }

      &.section__left {
        align-items: self-start;
        display: flex;
        justify-content: flex-start;
        padding: 6rem 0 0 2rem;
      }

      &.section_center {
        padding: 6rem 0;

        > .page-creation__paragraph {
          margin-bottom: 2rem;

          &:not(.title) {
            line-height: 1.4rem;
          }

          &.huge {
            font: 5rem/5rem var(--font-family-body-bold);
            margin-top: 6rem;
          }

          > a + span {
            margin-left: .4rem;
          }

          &.title__introduction {
            text-decoration: underline;
          }
        }

        > .page-creation__contact {
          display: flex;
          margin-top: 4rem;
        }
      }

      .page-creation__project {
        column-gap: 2rem;
        display: flex;
        margin-block: 2rem;
      }
    }

    > .section_project {
      display: grid;
      grid-column: 1 / span 3;
      grid-template-columns: 4rem auto 4rem;

      .page-creation__project {
        &.reversed {
          flex-direction: row-reverse;
        }

        > img {
          object-fit: cover;
        }

        > video {
          align-self: center;
          max-width: 848px;
          width: 50%;
        }

        > .page-creation__project-content {
          padding: 2rem;

          > .project__title {
            font: 2rem var(--font-family-title);
          }

          > .project__subtitle {
            font: 1.2rem var(--font-family-title);
          }

          > .project__description {
            margin-block: 2rem 4rem;
          }
        }
      }

      .side-title {
        margin-left: 32px;
        margin-top: 2rem;
      }
    }

    > img {
      grid-column: 1 / span 3;
      width: 100%;
    }

    > .page-creation__closing {
      align-items: center;
      background-color: var(--maroon);
      color: var(--white);
      display: flex;
      grid-column: 1 / span 3;
      justify-content: center;
      padding: 4rem;
    }
  }
}

@media (max-width: 1400px) {
  .page-creation {
    > .page-creation__container {
      grid-template-columns: 6rem 1fr 2rem;

      > .section_project {
        .page-creation__project {
          > .page-creation__project-content {
            padding: 1rem;
          }
        }
      }

      > .page-creation__closing {
        padding: 3.6rem;
      }
    }
  }
}

@media (max-width: 1200px) {
  .page-creation {
    > .page-creation__container {
      > .section_project {
        .page-creation__project {
          > .page-creation__project-content {
            > .project__title {
              font-size: 1.6rem;
            }

            > .project__subtitle {
              font-size: 1rem;
            }
          }
        }
      }

      > .page-creation__closing {
        padding: 3.2rem;
      }
    }
  }
}

@media (max-width: 1000px) {
  .page-creation {
    > .page-creation__container {
      grid-template-columns: 6rem 1fr 2rem;

      > .page-creation__closing {
        padding: 2.6rem;
      }
    }
  }
}

@media (max-width: 800px) {
  .page-creation {
    > .page-creation__container {
      > .section_project {
        .page-creation__project {
          column-gap: 1.4rem;

          > .page-creation__project-content {
            > .project__title {
              font-size: 1.2rem;
            }

            > .project__subtitle {
              font-size: .9rem;
            }

            > .project__description {
              margin-block: 1rem 2rem;
            }
          }
        }
      }

      > .page-creation__closing {
        padding: 2rem;
      }
    }
  }
}

@media (max-width: 600px) {
  .page-creation {
    > .page-creation__container {
      > .section_project {
        .page-creation__project {
          flex-direction: column-reverse;

          &.reversed {
            flex-direction: column-reverse;
          }

          > .page-creation__project-content {
            > .project__title {
              font-size: 1rem;
            }

            > .project__subtitle {
              font-size: .8rem;
            }

            > .project__description {
              margin-block: .8rem;
            }
          }
        }
      }
    }
  }
}
