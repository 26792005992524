.animated-text {
  transition: opacity 1s ease-out, transform 1s ease-out;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }

  &.hidden {
    opacity: 0;

    &.down {
      transform: translateY(-100%);
    }

    &.up {
      transform: translateY(100%);
    }
  }
}
