.bullet {
  cursor: pointer;
  fill: none;
  height: 10px;
  stroke: var(--white);
  stroke-width: 10%;
  transform: scale(1);
  transition: transform var(--short-transition) ease-out;
  width: 10px;

  &:hover {
    transform: scale(1.1);
  }

  &.full {
    fill: var(--white);
  }
}
