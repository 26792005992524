@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.page-agency {
  animation: 500ms fade-in forwards;
  opacity: 0;

  > .page-agency__section {
    align-items: flex-start;
    display: flex;
    position: relative;
  }

  .section__closing {
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    height: 448px;
    justify-content: center;
    padding: 0 8rem;
    width: 100%;

    &.first-name__closing {
      background-image: url('../assets/images/homepage/homepage1_1920.png');
    }

    &.philosophy__closing {
      background-image: url('../assets/images/homepage/homepage2_1920.png');
    }
  }

  > .page-agency__grid {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 53% 1fr;

    > .page-agency__grid-section {
      position: relative;

      &.section__manifest {
        background-color: var(--black);

        > .title-white {
          color: var(--white);
        }
      }

      &.section__left,
      &.section__right {
        height: 100%;
      }

      &.section__left {
        align-items: self-start;
        display: flex;
        justify-content: flex-start;
        padding: 4rem 0 0 2rem;
      }

      &.page-agency__philosophy {
        align-items: center;
        flex-direction: column;

        .philosophy__title {
          margin-bottom: 2rem;
        }

        > .philosophy__content {
          margin: 4rem 0;

          .philosophy__paragraph-title {
            font-family: var(--font-family-body-bold);
            margin-top: 2rem;
          }

          .philosophy__paragraph-content {
            &.spaced {
              margin: 2rem 0;
            }

            span {
              &:first-child {
                font-family: var(--font-family-body-bold);
                margin-right: .2rem;
              }
            }
          }
        }
      }

      &.page-agency__manifest {
        align-items: center;
        background-color: var(--black);
        color: var(--white);
        flex-direction: column;
        padding: 4rem 0;

        > .manifest__content {
          display: flex;
          align-items: stretch;
          flex-direction: column;

          .manifest__title {
            align-items: center;
            display: grid;
            grid-column-gap: 2rem;
            grid-template-columns: auto 1fr;
            margin-bottom: 2rem;

            &.manifest-right {
              grid-template-columns: 1fr auto;

              > .manifest__title-line-number {
                align-items: flex-start;
              }
            }

            > .manifest__title-line-number {
              align-items: flex-end;
              display: flex;
              flex-direction: column;

              > .manifest__title-number {
                font: 1rem var(--font-family-body-regular);
                margin-bottom: 1rem;
              }

              > .manifest__title-line {
                background-color: var(--white);
                height: 1px;
                transform: scaleX(0);
                transition: transform 2s ease-out;
                width: 100%;

                &.left {
                  transform-origin: left;
                }

                &.right {
                  transform-origin: right;
                }

                &.visible {
                  transform: scaleX(1);
                }
              }
            }
          }

          > .manifest__text {
            line-height: 1.6rem;
            margin-bottom: 3rem;
            max-width: 50%;

            &.manifest-right {
              align-self: flex-end;
              text-align: right;
            }
          }

          > .manifest__section {
            align-items: flex-end;
            display: flex;
            flex-direction: column;

            > .manifest__title {
              display: flex;
              margin-bottom: 1rem;
            }
          }
        }
      }
    }

    > .philosophy__closing {
      flex-direction: column;
      grid-column: 1 / span 3;

      > .philosophy__closing-content {
        > .philosophy__contact {
          color: var(--white);
          display: flex;
          margin-top: 4rem;

          > a {
            color: var(--white);
            margin-left: .4rem;
          }
        }
      }
    }
  }

  > .page-agency__first-name {
    background-color: var(--whitish);
    justify-content: space-evenly;
    padding: 4rem 4rem 4rem 2rem;

    > .first-name__drawing {
      position: relative;

      > .first-name__copyright {
        bottom: 180px;
        font-size: .8rem;
        position: absolute;
        right: 90px;
        transform: rotate(-90deg);
        transform-origin: left;
      }
    }

    > .first-name__content {
      margin: 0 10rem 0 1rem;

      > .first-name__title {
        margin-bottom: 6rem;
      }

      > .first-name__paragraph {
        line-height: 1.4rem;
        margin-bottom: 2rem;

        &.paragraph-bold {
          font-family: var(--font-family-body-bold);
        }
      }

      > .first-name__severine {
        font: 1.2rem var(--font-family-body-regular);
      }

      > .first-name__founder {
        font-family: var(--font-family-body-bold);
      }
    }

    > .first-name__pronunciation {
      align-items: center;
      display: flex;
      flex-direction: column;

      > a {
        > img {
          width: 10rem;
        }

        &:hover {
          box-shadow: 0 0 2px 0 rgb(0 0 0 / 20%);
        }
      }

      > .first-name__line {
        background-color: var(--black);
        height: 14rem;
        margin: 2rem 0;
        transform: scaleY(0);
        transform-origin: bottom;
        transition: transform 1s ease-out;
        width: 1px;

        &.visible {
          transform: scaleY(1);
        }
      }

      > .first-name__how {
        max-width: 10rem;
        text-align: center;
      }
    }
  }

  > .page-agency__team {
    align-items: center;
    color: var(--white);
    justify-content: space-evenly;

    &.founder {
      background-color: var(--blue);
    }

    &.creative-director {
      background-color: var(--khaki);
    }

    > .team__content {
      max-width: 40rem;

      &.founder {
        margin-left: -2rem;
        padding: 2rem 2rem 2rem 0;
      }

      &.creative-director {
        margin-right: -2rem;
        padding: 2rem 0 2rem 2rem;
      }

      > .team__label {
        font-size: 1.4rem;
        margin-bottom: 3rem;
      }

      > .team__paragraph {
        font-size: 1rem;
        line-height: 1.6rem;
        margin-bottom: 1.6rem;

        &.paragraph-highlight {
          font: 1.4rem var(--font-family-verbatim);
        }
      }

      > .founder__contact {
        color: var(--white);
        display: flex;
        margin-top: 4rem;

        > a {
          color: var(--white);
          margin-left: .4rem;
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  .page-agency {
    > .page-agency__grid {
      grid-template-columns: 1fr 60% 1fr;
    }
  }
}

@media (max-width: 1400px) {
  .page-agency {
    .section__closing {
      height: 327px;

      &.first-name__closing {
        background-image: url('../assets/images/homepage/homepage1_1400.png');
      }

      &.philosophy__closing {
        background-image: url('../assets/images/homepage/homepage2_1400.png');
      }
    }

    > .page-agency__grid {
      grid-template-columns: 1fr 70% 1fr;
    }

    > .page-agency__first-name {
      > .first-name__drawing {
        > .first-name__copyright {
          bottom: 140px;
          right: 60px;
        }
      }

      > .first-name__content {
        margin-right: 5rem;
      }
    }

    > .page-agency__team {
      max-height: 600px;

      > .team__content {
        > .team__paragraph {
          line-height: 1.5rem;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .page-agency {
    > .page-agency__first-name {
      > .first-name__drawing {
        > .first-name__copyright {
          bottom: 80px;
          right: 25px;
        }
      }
    }

    > .page-agency__team {
      > .team__content {
        > .team__label {
          margin-bottom: 2rem;
        }

        > .founder__contact {
          margin-top: 2rem;
        }
      }
    }

    .section__closing {
      padding: 0 2rem;
    }
  }
}

@media (max-width: 900px) {
  .page-agency {
    .section__closing {
      height: 210px;

      &.first-name__closing {
        background-image: url('../assets/images/homepage/homepage1_900.png');
      }

      &.philosophy__closing {
        background-image: url('../assets/images/homepage/homepage2_900.png');
      }
    }

    > .page-agency__grid {
      grid-template-columns: 1fr 80% 1fr;

      > .page-agency__grid-section {
        &.page-agency__manifest {
          > .manifest__content {
            > .manifest__text {
              max-width: 75%;
            }
          }
        }
      }

      > .philosophy__closing {
        > .philosophy__closing-content {
          > .philosophy__contact {
            margin-top: 2rem;
          }
        }
      }
    }

    > .page-agency__first-name {
      > .first-name__drawing {
        > .first-name__copyright {
          bottom: 40px;
          right: 0;
        }
      }

      > .first-name__content {
        margin-right: 2rem;
      }
    }

    > .page-agency__team {
      max-height: 400px;

      > .team__content {
        margin-left: -1rem;

        > .team__title {
          font-size: 2rem;
          line-height: 2rem;
        }

        > .team__label {
          font-size: 1.2rem;
          margin-bottom: .6rem;
        }

        > .team__paragraph {
          line-height: 1.3rem;
          margin-bottom: 1rem;

          &.paragraph-highlight {
            line-height: 1.3rem;
          }
        }

        > .founder__contact {
          margin-top: .4rem;
        }
      }
    }
  }

}

@media (max-width: 720px) {
  .page-agency {
    > .page-agency__team {
      > .team__content {
        > .team__title {
          font-size: 1.4rem;
          line-height: 1.4rem;
        }

        > .team__label {
          font-size: 1rem;
          margin-bottom: .4rem;
        }

        > .team__paragraph {
          font-size: .9rem;
          line-height: 1.2rem;
          margin-bottom: .8rem;

          &.paragraph-highlight {
            font-size: 1.2rem;
            line-height: 1.2rem;
          }
        }

        > .founder__contact {
          margin-top: .4rem;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .page-agency {
    > .page-agency__grid {
      grid-template-columns: 4rem 75% 1fr;

      > .page-agency__grid-section {
        &.section__left {
          align-items: self-start;
          display: flex;
          justify-content: center;
          padding: 4rem 0 0;
        }
      }
    }

    > .page-agency__name {
      column-gap: 2rem;
      padding: 4rem 2rem;
    }

    > .page-agency__team {
      flex-direction: column;
      max-height: unset;

      &.creative-director {
        flex-direction: column-reverse;
      }

      > .team__content {
        max-width: unset;

        &.founder,
        &.creative-director {
          margin: 0;
          padding: 2rem;
        }

        > .team__title {
          font-size: 3rem;
          line-height: 2.8rem;
        }

        > .team__label {
          font-size: 1.4rem;
          margin-bottom: 3rem;
        }

        > .team__paragraph {
          font-size: 1rem;
          line-height: 1.6rem;
          margin-bottom: 1.6rem;

          &.paragraph-highlight {
            font: 1.4rem var(--font-family-title);
          }
        }

        > .founder__contact {
          margin-top: 4rem;
        }
      }
    }

    > .page-agency__first-name {
      // sass-lint:disable-block indentation
      align-items: flex-start;
      display: grid;
      grid-template: 'drawing drawing'
                     'title content'
                     / 4rem 1fr;
      padding: 2rem 0;

      > .first-name__drawing {
        grid-area: drawing;
        margin: 0 auto 2rem;
      }

      > .side-title {
        grid-area: title;
        margin: 0 auto;
      }

      > .first-name__content {
        grid-area: content;
        margin-left: 0;

        > .first-name__title {
          margin-bottom: 3rem;
        }
      }

      > .first-name__pronunciation {
        display: none;
      }
    }

    .section__closing {
      &.philosophy__closing {
        background-image: url('../assets/images/homepage/homepage2_600.png');
        height: 260px;
      }
    }
  }
}

@media (max-width: 450px) {
  .page-agency {
    > .page-agency__team {
      > .team__content {
        > .team__title {
          font-size: 2rem;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .page-agency {
    .section__closing {
      height: 93px;

      &.first-name__closing {
        background-image: url('../assets/images/homepage/homepage1_400.png');
      }

      &.philosophy__closing {
        background-image: url('../assets/images/homepage/homepage2_400.png');
        height: 160px;
      }
    }
  }
}
