.carousel {
  &.manifest {
    > .carousel__slider {
      > .carousel__slide {
        &.image1,
        &.image5 {
          background-image: url('../assets/images/manifest/manifest1_1920.png');
        }

        &.image2 {
          background-image: url('../assets/images/manifest/manifest2_1920.png');
        }

        &.image3 {
          background-image: url('../assets/images/manifest/manifest3_1920.png');
        }

        &.image4 {
          background-image: url('../assets/images/manifest/manifest4_1920.png');
        }
      }
    }
  }
}

@media (width <= 1400px) {
  .carousel {
    &.manifest {
      > .carousel__slider {
        > .carousel__slide {
          &.image1,
          &.image5 {
            background-image: url('../assets/images/manifest/manifest1_1400.png');
          }

          &.image2 {
            background-image: url('../assets/images/manifest/manifest2_1400.png');
          }

          &.image3 {
            background-image: url('../assets/images/manifest/manifest3_1400.png');
          }

          &.image4 {
            background-image: url('../assets/images/manifest/manifest4_1400.png');
          }
        }
      }
    }
  }
}

@media (width <= 900px) {
  .carousel {
    &.manifest {
      > .carousel__slider {
        > .carousel__slide {
          &.image1,
          &.image5 {
            background-image: url('../assets/images/manifest/manifest1_900.png');
          }

          &.image2 {
            background-image: url('../assets/images/manifest/manifest2_900.png');
          }

          &.image3 {
            background-image: url('../assets/images/manifest/manifest3_900.png');
          }

          &.image4 {
            background-image: url('../assets/images/manifest/manifest4_900.png');
          }
        }
      }
    }
  }
}

@media (width <= 400px) {
  .carousel {
    &.manifest {
      > .carousel__slider {
        > .carousel__slide {
          > .slide__text-container {
            > .slide__logo {
              width: 120px;
            }
          }

          &.image1,
          &.image5 {
            background-image: url('../assets/images/manifest/manifest1_400.png');
          }

          &.image2 {
            background-image: url('../assets/images/manifest/manifest2_400.png');
          }

          &.image3 {
            background-image: url('../assets/images/manifest/manifest3_400.png');
          }

          &.image4 {
            background-image: url('../assets/images/manifest/manifest4_400.png');
          }
        }
      }
    }
  }
}

// Phones
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .carousel {
    &.manifest {
      > .carousel__slider {
        > .carousel__slide {
          &.image1,
          &.image5 {
            background-image: url('../assets/images/manifest/manifest1_1920.png');
          }

          &.image2 {
            background-image: url('../assets/images/manifest/manifest2_1920.png');
          }

          &.image3 {
            background-image: url('../assets/images/manifest/manifest3_1920.png');
          }

          &.image4 {
            background-image: url('../assets/images/manifest/manifest4_1920.png');
          }
        }
      }
    }
  }
}
