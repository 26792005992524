@import 'fonts.scss';

:root {
  --green: #8D9C77;
  --khaki: #9D903D;
  --blue: #405E67;
  --bright-green: #D6C126;
  --maroon: #381400;
  --light-blue: #A0B1C2;
  --whitish: #F8F8F8;
  --white: #FFF;
  --black: #000;
  --white-transparent-10: rgb(255 255 255 / 10%);
  --black-transparent-10: rgb(0 0 0 / 10%);
  --black-transparent-30: rgb(0 0 0 / 30%);
  --modal-overlay-background: rgb(0 0 0 / 40%);

  --border-radius-small: .2rem;
  --border-radius-big: .5rem;

  --font-family-title: HelveticaBold, serif;
  --font-family-verbatim: Bodoni, serif;
  --font-family-body-regular: Helvetica, sans-serif;
  --font-family-body-bold: HelveticaBold, sans-serif;

  --font-size: normal;
  --font-size-title: 2.8rem;

  --exergue-length: 140px;
  --exergue-margin: 20px;
  --exergue-length-with-margin: calc(var(--exergue-length) + var(--exergue-margin));

  --header-height: 7rem;
  --footer-height: 7rem;
  --short-transition: 200ms;
  --medium-transition: 500ms;
}

html {
  -moz-osx-font-smoothing: auto;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  font-size: var(--font-size);
  height: 100%;
  user-select: none;
  width: 100%;

  * {
    box-sizing: border-box;
    white-space: pre-line;
  }

  button {
    font-size: inherit;
  }

  body {
    align-items: stretch;
    background-color: var(--white);
    color: var(--black);
    display: flex;
    flex-direction: column;
    font: 1rem var(--font-family-body-regular);
    height: 100%;
    justify-content: stretch;
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 100%;

    > .root {
      align-items: stretch;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: stretch;
      min-width: 320px;
      overflow: hidden auto;
      width: 100%;

      > .page {
        align-items: stretch;
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
        justify-content: stretch;
        position: relative;
        width: 100%;

        .side-title {
          font-family: var(--font-family-body-bold);
          transform: scale(-1);
          writing-mode: vertical-lr;
        }

        .title {
          font: var(--font-size-title) / 3rem var(--font-family-title);

          &.verbatim {
            font-family: var(--font-family-verbatim);
          }

          &.title-white {
            color: var(--white);
          }

          &.normal-line-height {
            line-height: normal;
          }
        }

        .exergue {
          margin-left: var(--exergue-length-with-margin);
          position: relative;

          &::before {
            background-color: var(--black);
            content: '';
            height: 1px;
            left: calc(var(--exergue-length-with-margin) * -1);
            margin-right: 20px;
            position: absolute;
            top: 10px;
            width: var(--exergue-length);
          }
        }
      }

      .no-style-button {
        align-items: center;
        background-color: transparent;
        border: 0;
        cursor: pointer;
        display: flex;
        justify-content: center;
      }

      a {
        &.underlined {
          border-bottom: 1px solid var(--black);
          color: var(--black);
          font: .8rem var(--font-family-body-bold);
          padding-bottom: .2rem;
          text-decoration: none;
          text-transform: uppercase;

          &:hover {
            color: rgba(0, 0, 0, .8);
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  :root {
    --font-size: .93rem;
  }
}

@media (max-width: 1000px) {
  :root {
    --font-size: .87rem;
  }
}

@media (max-width: 900px) {
  :root {
    --font-size: .81rem;
  }

  html {
    > body {
      > .root {
        > .page {
          .title {
            font-size: 2rem;
            line-height: 2.2rem;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  :root {
    --font-size: .87rem;
    --font-title: 2rem var(--font-family-title);
    --header-height: 5rem;
    --exergue-length: 100px;
    --exergue-margin: 15px;
  }

  html {
    body {
      > .root {
        > .page {
          .title {
            line-height: 2rem;
          }
        }
      }
    }
  }
}

@media (max-width: 400px) {
  :root {
    --font-size: .68rem;
    --font-title: 2rem var(--font-family-title);
    --exergue-length: 60px;
    --exergue-margin: 10px;
  }

  html {
    body {
      > .root {
        > .page {
          .title {
            font-size: 1.8rem;
            line-height: 1.8rem;
          }
        }
      }
    }
  }
}
