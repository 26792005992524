.newsletter-form {
  align-items: stretch;
  display: flex;
  flex-direction: column;

  > .newsletter-form__row {
    align-items: stretch;
    display: flex;

    input[type='email'] {
      background-color: var(--blue);
      border: 2px solid var(--blue);
      box-sizing: border-box;
      color: var(--white);
      font: 1rem var(--font-family-body-regular);
      padding: 1rem;
      width: 20rem;

      &:focus {
        outline: none !important; // sass-lint:disable-line no-important
        border: 2px solid var(--bright-green);
      }
    }

    ::placeholder {
      color: rgba(255, 255, 255, .6);
    }

    > button {
      background-color: var(--bright-green);
      border: 2px solid var(--bright-green);
      color: var(--white);
      cursor: pointer;
      font: 1rem var(--font-family-body);
      padding: 1rem;
      text-transform: uppercase;

      &:hover {
        &:not(:disabled) {
          background-color: rgba(214, 193, 38, .8);
          border: 2px solid transparent;
        }
      }

      &:focus {
        border: 2px solid var(--blue);
        border-radius: 0;
        outline: none;
      }

      &:disabled {
        opacity: .5;
      }
    }
  }

  > .newsletter-form__status {
    margin-top: 1rem;

    &.status-success {
      color: var(--black);
    }

    &.status-error {
      color: var(--black);
      text-decoration: underline;
    }
  }
}

@media (max-width: 450px) {
  .newsletter-form {
    > .newsletter-form__row {
      > input[type='email'],
      > button {
        padding: .4rem;
      }
    }
  }
}

@media (max-width: 400px) {
  .newsletter-form {
    > .newsletter-form__row {
      > input[type='email'] {
        width: initial;
      }
    }
  }
}
