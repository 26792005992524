.social {
  align-items: center;
  display: flex;

  > a {
    align-items: center;
    display: flex;

    &:not(:last-child) {
      margin-right: 2rem;
    }

    &:hover {
      opacity: .8;
    }

    > img {
      height: 24px;
    }
  }
}

@media (max-width: 1200px) {
  .social {
    > a {
      &:not(:last-child) {
        margin-right: 1.4rem;
      }
    }
  }
}

@media (max-width: 1000px) {
  .social {
    > a {
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
}

@media (max-width: 900px) {
  .social {
    > a {
      &:not(:last-child) {
        margin-right: .6rem;
      }

      > img {
        height: 20px;
      }
    }
  }
}

@media (max-width: 400px) {
  .social {
    > a {
      &:not(:last-child) {
        margin-right: .4rem;
      }

      > img {
        height: 18px;
      }
    }
  }
}
