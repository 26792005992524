.footer {
  align-items: center;
  background-color: var(--white);
  display: flex;
  height: var(--footer-height);
  justify-content: space-between;
  padding: 0 40px;
  position: relative;

  > .footer__links {
    align-items: center;
    display: flex;

    > a {
      &:not(:last-child) {
        margin-right: 40px;
      }
    }

    > button {
      background-color: var(--black);
      border: 0;
      color: var(--white);
      cursor: pointer;
      font: .8rem var(--font-family-body);
      padding: .5rem 1rem;
      text-transform: uppercase;

      &:hover {
        background-color: rgba(0, 0, 0, .8);
      }
    }
  }

  > .footer__version {
    bottom: .2rem;
    color: #AAA;
    font-size: .6rem;
    position: absolute;
    right: .2rem;
  }
}

@media (max-width: 1200px) {
  .footer {
    > .footer__links {
      > a {
        &:not(:last-child) {
          margin-right: 30px;
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .footer {
    padding: 0 20px;

    > .footer__links {
      > a {
        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .footer {
    padding: 0 10px;

    > .footer__links {
      > a {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}

@media (max-width: 720px) {
  .footer {
    align-items: center;
    flex-direction: column;
    height: auto;
    padding: 3rem 1rem;

    > .footer__links {
      flex-direction: column;
      margin: 1rem 0 0;

      > a {
        &:not(:last-child) {
          margin: 0 0 1rem;
        }
      }
    }
  }
}
