.page-project {
  --margin-block: 4rem;
  --margin-inline: 5rem;
  --project-big-size: 2rem;
  --project-huge-size: 3rem;

  align-items: stretch;
  display: flex;
  flex-direction: column;

  > .page-project__header {
    align-items: baseline;
    display: grid;
    flex-grow: 1;
    //grid-column-gap: 10rem;
    grid-template-columns: auto auto;
    margin: 0 var(--margin-inline) var(--margin-block);

    > .project__bullets {
      align-items: flex-start;
      background-color: var(--black-transparent-10);
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      grid-row-gap: 2rem;
      padding: 1rem;
    }

    > .project__introduction {
      margin-left: 10rem;

      > .project__title {
        font: var(--project-big-size) var(--font-family-title);
        text-decoration: underline;
      }

      > .project__description-huge {
        font: var(--project-huge-size) var(--font-family-title);
        margin-block: 2rem;
      }
    }
  }

  > img {
    &.project__image-full-width {
      margin-inline: var(--margin-inline);
    }
  }

  > figure {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-inline: var(--margin-inline);
    position: relative;

    > video {
      width: 100%;
    }

    > .video__overlay {
      align-items: center;
      bottom: 0;
      display: flex;
      height: 100%;
      justify-content: center;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;

      &.video__overlay-clickable {
        cursor: pointer;
      }

      > button {
        background-color: var(--black-transparent-10);
        border: 0;
        border-radius: 50%;
        cursor: pointer;
        padding: 1rem;
        z-index: 100;

        &:hover {
          background-color: var(--black-transparent-30);
        }

        > img {
          height: 4rem;
          transform: translateX(.5rem);
          width: 4rem;
        }
      }
    }
  }

  > .project__stake {
    align-items: baseline;
    display: grid;
    column-gap: var(--margin-inline);
    grid-template-columns: 1fr 1fr;
    margin: var(--margin-block) 20%;

    > .project__stake-title {
      font: var(--project-big-size) var(--font-family-title);
      text-align: right;
    }
  }

  > .project__four-shots {
    align-items: center;
    grid-gap: 2rem;
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    margin: 0 var(--margin-inline) var(--margin-block);
  }

  > .project__vintages-presentation {
    align-items: center;
    background-color: var(--blue);
    display: grid;
    grid-template-columns: 1fr 1fr;

    > .project__vintages-list {
      align-items: stretch;
      color: var(--white);
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-inline: var(--margin-inline);
      grid-row-gap: 1rem;

      > div {
        text-align: center;
      }

      > .project__vintages-year {
        font: var(--project-big-size) var(--font-family-verbatim);
        padding-top: 1rem;

        &:not(:first-child) {
          border-top: 1px solid var(--white);
        }
      }
    }
  }

  > .project__screens {
    align-items: center;
    background-color: var(--maroon);
    display: grid;
    grid-template-columns: 1fr 1fr;

    // sass-lint:disable no-empty-rulesets
    > .project__screens-computer {
    }

    > .project__screens-phones {
      align-items: center;
      column-gap: 1rem;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      justify-content: stretch;
    }
  }

  > .project__end {
    align-items: baseline;
    column-gap: 2rem;
    display: flex;
    // sass-lint:disable  shorthand-values
    margin: var(--margin-block) var(--margin-inline);

    > .project__thanks {
      font: var(--project-big-size) var(--font-family-title);
    }

    > .project__cast {
      --cast-margin: .5rem;

      display: flex;
      column-gap: var(--cast-margin);

      > span {
        &:not(:first-child) {
          border-left: 1px solid var(--black);
          padding-left: var(--cast-margin);
        }
      }
    }

    > .project__contact {
      border: 1px solid var(--black);
      color: var(--black);
      font-family: var(--font-family-body-bold);
      margin-left: auto;
      padding: .5rem;
      text-transform: uppercase;

      &:hover {
        background-color: rgb(0 0 0 / 10%);
      }
    }
  }

  > .project__making-of {
    align-items: center;
    grid-gap: 2rem;
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    margin: 0 var(--margin-inline) var(--margin-block);
  }

  > a {
    align-self: center;
  }
}

@media (max-width: 1400px) {
  .page-project {
    --margin-block: 3rem;
    --margin-inline: 4rem;
    --project-big-size: 1.8rem;
    --project-huge-size: 2.6rem;

    > .project__stake {
      margin-inline: 15%;
    }

    > .page-project__header {
      > .project__introduction {
        margin: 5rem;

        > .project__description-huge {
          margin-block: 1.6rem;
        }
      }
    }

    > .project__vintages-presentation {
      > .project__vintages-list {
        grid-row-gap: .8rem;
        padding-inline: 3rem;

        > .project__vintages-year {
          font-size: 1.6rem;
          padding-top: .6rem;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .page-project {
    --margin-block: 2rem;
    --margin-inline: 3rem;
    --project-big-size: 1.4rem;
    --project-huge-size: 2rem;

    > .project__stake {
      margin-inline: 10%;
    }

    > .page-project__header {
      > .project__introduction {
        margin: 2rem;

        > .project__description-huge {
          margin-block: 1.2rem;
        }
      }
    }

    > .project__vintages-presentation {
      > .project__vintages-list {
        grid-row-gap: .6rem;
        padding-inline: 2rem;

        > .project__vintages-year {
          font-size: 1.1rem;
          padding-top: .3rem;
        }
      }
    }
  }
}

@media (max-width: 750px) {
  .page-project {
    > .project__stake {
      grid-template-columns: .6fr 1fr;
      margin-inline: 5%;
    }

    > .project__vintages-presentation {
      > .project__vintages-list {
        grid-row-gap: .3rem;
        padding-inline: 1rem;

        > .project__vintages-year {
          font-size: 1rem;
        }
      }
    }

    > .project__end {
      flex-direction: column;
      grid-row-gap: 2rem;
      margin-inline: .5rem;

      > .project__contact {
        margin-inline: auto;
      }
    }
  }
}

@media (max-width: 600px) {
  .page-project {
    --margin-block: 1rem;
    --margin-inline: 1rem;
    --project-big-size: 1rem;
    --project-huge-size: 1.3rem;

    > .page-project__header {
      > .project__introduction {
        margin: 1rem;

        > .project__description-huge {
          margin-block: 1rem;
        }
      }
    }

    > .project__vintages-presentation {
      display: flex;
      flex-direction: column-reverse;

      > .project__vintages-list {
        grid-row-gap: .6rem;
        margin-block: 2rem;
        padding-inline: .8rem;

        > div {
          font-size: .7rem;
        }

        > .project__vintages-year {
          font-size: .8rem;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .page-project {
    --margin-block: 1rem;
    --margin-inline: 0;
    --project-big-size: .8rem;
    --project-huge-size: 1rem;

    > .project__stake {
      column-gap: 1rem;
      grid-template-columns: .4fr 1fr;
    }

    > .page-project__header {
      > .project__introduction {
        margin: 1rem;

        > .project__description-huge {
          margin-block: 1rem;
        }
      }
    }
  }
}
