@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
}

.menu__wrapper {
  inset: 0;
  position: fixed;
  z-index: 600;

  .menu__overlay {
    animation: fadeIn 850ms cubic-bezier(.14, .78, .36, 1);
    background: var(--modal-overlay-background);
    inset: 0;
    position: absolute;
    z-index: 610;
  }
}

.menu {
  position: relative;
  z-index: 620;

  &.menu__label,
  > .menu__label {
    font-family: var(--font-family-body-bold);
    letter-spacing: .2rem;
    padding-bottom: .2rem;
  }

  &.menu__closed {
    color: var(--black);
    cursor: pointer;
    text-transform: uppercase;

    &::after {
      border-bottom: 1px solid var(--black);
      bottom: -1px;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      right: .2rem;
    }
  }

  &.menu__opened {
    animation: slideIn 500ms cubic-bezier(.14, .78, .36, 1);
    background-color: var(--green);
    color: var(--white);
    min-width: 25rem;
    padding: 2rem 0 2rem 2rem;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 630;

    > .menu__header,
    > .menu__footer {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-right: 2rem;
    }

    > .menu__header {
      > .close {
        padding: .3rem;

        > img {
          height: 1.1rem;
        }
      }
    }

    > .menu__footer {
      > .menu__languages {

        input[type='radio'] {
          display: none;

          &:checked + label {
            border-bottom: 1px solid var(--white);
            cursor: default;
          }

          &:not(:checked) + label {
            &:hover {
              opacity: .8;
            }
          }
        }

        input[type='radio'] + label {
          color: var(--white);
          cursor: pointer;
          flex-grow: 1;
          font-size: .8rem;
          padding: .2rem 0;

          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }
    }

    > .menu__links {
      display: flex;
      flex-direction: column;
      margin: 5rem 0 11rem;

      > .menu__item_toggle,
      > .menu__item {
        border-bottom: .15rem solid var(--white);
        color: var(--white);
        cursor: pointer;
        font-size: 1.7rem;
        line-height: 2.2rem;
        text-decoration: none;
        text-transform: uppercase;
      }

      > .menu__item_accordion {
        display: flex;
        flex-direction: column;
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        padding-top: .6rem;
        transition: max-height var(--medium-transition) ease-out, opacity var(--medium-transition) ease-out;

        &.expanded {
          max-height: 10rem;
          opacity: 1;

          > a {
            pointer-events: all;
          }
        }

        > a {
          color: var(--white);
          line-height: 1.6rem;
          pointer-events: none;
          text-decoration: none;

          &:last-child {
            margin-bottom: 2rem;
          }
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .menu {
    &.menu__opened {
      width: 100%;
    }
  }
}
