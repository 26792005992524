.film {
  align-items: flex-start;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: clip;
  position: relative;
  width: 100%;

  > video {
    background-size: cover;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 100;
  }

  > button {
    &.skip {
      background-color: transparent;
      border: 1px solid var(--white);
      color: var(--white);
      cursor: pointer;
      display: none;
      font: .8rem var(--font-family-body);
      letter-spacing: .08rem;
      padding: .5rem 1rem;
      position: absolute;
      text-transform: uppercase;
      z-index: 1000;

      &.visible {
        display: revert;
      }

      &:hover {
        background-color: var(--black-transparent-30);
      }
    }

    &.sound {
      background-color: var(--black-transparent-10);
      border: 0;
      border-radius: .2em;
      cursor: pointer;
      left: 2em;
      padding: .1em .4em;
      position: absolute;
      top: 2em;
      z-index: 1000;

      &:hover {
        > img {
          opacity: 1;
        }
      }

      > img {
        height: 32px;
        opacity: .8;
        width: 32px;
      }
    }
  }
}
