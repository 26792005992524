.page-contact {
  > .page-contact__content {
    align-items: flex-start;
    background-color: var(--khaki);
    color: var(--white);
    column-gap: 4rem;
    display: flex;
    flex-grow: 1;
    padding: 6rem 0;
    position: relative;

    > .section__left {
      padding-left: 2rem;
      width: 10%;
    }

    > .page-contact__info {
      font-size: 1.4rem;

      > .page-contact__egon {
        font: 2rem/2.8rem var(--font-family-body-bold);
      }

      > a {
        color: var(--white);
        display: block;
        margin-top: 2rem;
      }
    }
  }

  > .page-contact__landscape {
    background-image: url('../assets/images/contact/contact_1920.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 350px;
  }
}

@media (max-width: 1400px) {
  .page-contact {
    > .page-contact__landscape {
      background-image: url('../assets/images/contact/contact_1400.png');
    }
  }
}

@media (max-width: 900px) {
  .page-contact {
    > .page-contact__content {
      height: 100%;
    }

    > .page-contact__landscape {
      background-image: url('../assets/images/contact/contact_900.png');
    }
  }
}

@media (max-width: 600px) {
  .page-contact {
    > .page-contact__content {
      // sass-lint:disable-block indentation
      display: grid;
      grid-template: 'title logo' 120px
                     '.     content'
                     / 4rem 1fr;
      padding: 4rem 0;

      > img {
        grid-area: logo;
      }

      > .page-contact__info {
        grid-area: content;
        margin-right: 1rem;
      }
    }
  }
}

@media (max-width: 400px) {
  .page-contact {
    > .page-contact__content {
      // sass-lint:disable-block indentation
      grid-template: 'title logo' 120px
                     '.     content'
                     / 2rem 1fr;
    }

    > .page-contact__landscape {
      background-image: url('../assets/images/contact/contact_400.png');
    }
  }
}
