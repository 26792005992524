.page-expertises {
  > .page-expertises__container {
    align-items: baseline;
    display: grid;
    grid-template-columns: 1fr 4fr 6fr 2fr;

    > .page-expertises__section {
      position: relative;

      &.section__left,
      &.section__right {
        height: 100%;
      }

      &.section__left {
        align-items: self-start;
        align-self: flex-start;
        display: flex;
        justify-content: flex-start;
        padding: 6rem 0 0 2rem;
      }
    }

    > .page-expertises__title {
      padding: 6rem 0;
      text-align: right;
    }

    > .page-expertises__description {
      line-height: 1.4rem;
      margin-left: 2rem;
      max-width: 44rem;
      padding: 6rem 0;

      > div {
        &:first-child {
          margin-bottom: 2rem;
        }
      }
    }

    > .page-expertises__listing {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      color: var(--white);
      column-gap: 8rem;
      display: flex;
      grid-column: 1 / span 4;
      justify-content: center;
      padding: 4rem;

      &.expertises__image1 {
        background-image: url('../assets/images/expertise/expertises1_1920.png');
      }

      &.expertises__image2 {
        background-image: url('../assets/images/expertise/expertises2_1920.png');
      }

      &.expertises__image3 {
        background-image: url('../assets/images/expertise/expertises3_1920.png');
      }

      &.expertises__image4 {
        background-image: url('../assets/images/expertise/expertises4_1920.png');
      }

      &.expertises__image5 {
        background-image: url('../assets/images/expertise/expertises5_1920.png');
      }

      .listing__title {
        font-family: var(--font-family-title);
        font-size: 1.2rem;
        margin-bottom: 1rem;
        position: relative;

        &::before {
          background-color: var(--white);
          content: '';
          height: 1px;
          position: absolute;
          top: -1rem;
          width: 2.5rem;
        }

        &:not(:first-child) {
          margin-top: 2rem;
        }
      }

      .listing__item {
        font-size: .9rem;
        line-height: 1.4rem;
      }
    }

    > .page-expertises__closing {
      align-items: center;
      color: var(--white);
      display: flex;
      grid-column: 1 / span 4;
      justify-content: center;
      padding: 4rem;

      &.closing1 {
        background-color: var(--khaki);
      }

      &.closing2 {
        background-color: var(--blue);
      }

      &.closing3 {
        background-color: var(--bright-green);
      }

      &.closing4 {
        background-color: var(--maroon);
      }

      &.closing5 {
        background-color: var(--light-blue);
      }
    }
  }
}

@media (max-width: 1000px) {
  .page-expertises {
    > .page-expertises__container {
      grid-template-columns: 1fr 4fr 6fr 1fr;
    }
  }
}

@media (max-width: 600px) {
  .page-expertises {
    > .page-expertises__container {
      > .page-expertises__listing {
        column-gap: 4rem;
      }
    }
  }
}
