.page-join-us {
  > .page-join-us__content {
    align-items: flex-start;
    background-color: var(--blue);
    color: var(--white);
    column-gap: 4rem;
    display: flex;
    flex-grow: 1;
    padding: 6rem 0;
    position: relative;

    > .section__left {
      padding-left: 2rem;
      width: 10%;
    }

    > .page-join-us__info {
      line-height: 1.4rem;
      margin-right: 2rem;

      > .page-join-us__title {
        margin-bottom: 2rem;
      }

      > a {
        color: var(--white);
        display: block;
        margin-top: 2rem;
      }
    }
  }
}

@media (max-width: 700px) {
  .page-join-us {
    > .page-join-us__content {
      display: grid;
      // sass-lint:disable-block indentation
      grid-template: 'title logo' 120px
                     '.     content'
                     / 4rem 1fr;
      padding: 4rem 0;

      > img {
        grid-area: logo;
      }

      > .page-join-us__info {
        grid-area: content;
        margin-right: 1rem;
      }
    }
  }
}

@media (max-width: 500px) {
  .page-join-us {
    > .page-join-us__content {
      // sass-lint:disable-block indentation
      grid-template: 'title logo' 120px
                     '.     content'
                     / 2rem 1fr;
    }
  }
}
