.page-legal-notice {
  > .legal-notice__container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 60%;

    > .legal-notice__title {
      align-self: center;
      font: 2.5rem var(--font-family-body-bold);
      margin: 2rem 0;

      &.page-title {
        text-decoration: underline;
      }
    }

    > .legal-notice__paragraph {
      margin-bottom: 1rem;

      &.paragraph-center {
        align-self: center;
      }

      &.paragraph-underline {
        text-decoration: underline;
      }

      &.paragraph-date {
        font-size: 1.2rem;
      }

      &.paragraph-welcome {
        margin: 2rem 0;
      }

      &.paragraph-bold {
        font-family: var(--font-family-body-bold);
      }

      &.paragraph-margin-v {
        margin: 1rem 0;
      }

      &.paragraph-address {
        margin-bottom: 0;
      }

      &.paragraph-red {
        color: red;
      }

      a {
        margin-left: .3rem;
      }
    }

    > ul {
      list-style-type: '- ';
      margin: -1rem 0 2rem;
    }
  }
}

@media (max-width: 1000px) {
  .page-legal-notice {
    > .legal-notice__container {
      max-width: 90%;
    }
  }
}
